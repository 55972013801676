.body_search{
  margin: 0; 
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  align-items: center;
  
}

.input {
  padding: 10px;
  background: black;
  border: 4px solid rgb(223, 223, 14);
  border-radius: 50px;
  box-sizing: border-box;
  font-family: Comic Sans MS;
  font-size: 26px;
  color: rgb(255, 255, 255);
  outline: none;
  transition: .5s;
}


.box_s:hover input{
  /* width: 350px; */
  background: rgb(14, 14, 14);
  border: 4px solid rgb(223, 223, 14);
  border-radius: 50px;
}
.box_s ::before {
  background-color: #080808;
}
.box_s i{
  position: absolute;
  top: 50%;
  right: 15px;
  /* transform: translate(-50%,-50%); */
  font-size: 26px;
  color: #ffd52d;
  transition: .2s;
}
.box_s:hover i{
  opacity: 0;
  z-index: -1;
}

.searchCat {
  margin-top: 60px;
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
}
.btn-cat {
  padding: 12px;
  margin-right: 4px;
}

.blackButton{
  position: relative;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 1000;

  height: 95px;
  width: 270px;
  margin: 0 40px;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  background: #dee605;
  overflow: hidden;
  transition: 0.6s;
}

.whiteButton{
  position:relative;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 1000;
  opacity: 75%;
  height: 85px;
  width: 260px;
  margin: 0 40px;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  background: #f9f9a9  ;
  overflow: hidden;
  transition: 0.6s;
}

h1 {
  position: relative;
  padding: 0;
  margin: 0;
  /* margin-top: 3.6%; */
  margin-bottom: 2%;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 90px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
h1 em {
  font-style: normal;
  font-weight: 600;
}
.headText h1 {
  text-align: center;
  color:#222; font-size:60px; font-weight:400;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-transform: uppercase;
  word-spacing: 1px; letter-spacing:2px; color:#ffd52d;
}
.headText h1 span {
  line-height:2em; padding-bottom:15px;
  text-transform: none;
  font-size:.7em;
  font-weight: normal;
  font-style: italic; font-family: "Playfair Display","Bookman",serif;
  color:#fbf7f7; letter-spacing:-0.005em; word-spacing:1px;
  letter-spacing:none;
}
.headText h1:after, .headText h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 45px;
  height: 4px;
  content: "";
  right: 45px; 
  margin:auto;
  background-color: #ccc;
}
.headText h1:before { 
background-color:#e8fba2;
  left:45px; width:90px;
}

.card_search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card_search {
  border: 1px solid #bfc907;
  border-radius: 5px;
  background-color: #ccc;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 10px;
  max-width: 300px;
  width: 100%;
}

.card_search h2 {
  margin-top: 0;
}

.card_search p {
  margin-bottom: 5px;
}

.main {
  background-image: url('https://png.pngtree.com/background/20220722/original/pngtree-breakfast-wheat-western-toast-food-picture-picture-image_1713117.jpg');
  background-repeat: no-repeat;
  background-size:cover ;
}

#submitButton {
  padding: 14px 34px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #464542;
  border: none;
  border-radius: 50px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

#submitButton:hover {
  transform: translateY(-5px);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
}

#submitButton:active {
  transform: translateY(0px);
  box-shadow: none;
}

#submitButton:focus {
  outline: none;
}

#submitButton:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.15);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

#submitButton:hover:before {
  opacity: 1;
}

#submitButton:active:before {
  opacity: 0.5;
}

select { width: 400px; text-align: center; }
select .lt { text-align: center; }

.dropDown {
  position:relative;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 1000;
  opacity: 75%;
  height: 85px;
  width: 260px;
  margin: 0 40px;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  background: #f9f9a9  ;
  overflow: hidden;
  transition: 0.6s;
}


@media only screen and (max-width: 425px){
  .input {
    font-size: 17px
  }
  #submitButton{
    font-size: 12px
  }
  .whiteButton{
    font-size: 18px;
    height: 55px;
    width: 200px;
  }
  .dropDown{
    font-size: 18px;
    height: 55px;
    width: 200px;
  }
  .blackButton{
    font-size: 24px;
    height: 65px;
    width: 210px;
  }
}